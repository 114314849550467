import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/sovereigns/*" />
    <RAC path="/zh-cn/sovereigns/*" />
    <RAC path="/zh-tw/sovereigns/*" />
    <RAC path="/pl/sovereigns/*" />
    <RAC path="/ru/sovereigns/*" />
    <RAC path="/it/sovereigns/*" />
    <RAC path="/de/sovereigns/*" />
    <RAC path="/es/sovereigns/*" />
    <RAC path="/fr/sovereigns/*" />
    <RAC path="/ar/sovereigns/*" />
    <RAC path="/id/sovereigns/*" />
    <RAC path="/pt/sovereigns/*" />
    <RAC path="/ko/sovereigns/*" />
    <DynamicNotFoundPage default />
  </Router>
)
